import * as React from 'react';
import Header from '../../layouts/header-dark';
import Footer from '../../layouts/footer';
import '../../styles/_bootstrap.scss';

class Privacy extends React.PureComponent<{}, {}> {
    public render() {
        return (
            <>
                <Header />
                <div className="page">
                    <div className="container-fluid hero">
                    </div>
                    <div className="container body">
                        <h1 className="text-center padding-top-L padding-bottom-L">Privacy Policy</h1>
                        <p>Here at DirekTek we take your privacy very seriously and are committed to respecting your privacy.</p>

                        <p>We will only ever collect, keep, use or share your information where you have given us your consent,
                            or if we are legally obliged to do so. This policy will tell you how we use your personal information
                            and sets out how we comply with the European Union&#8217;s (including the UK) data protection requirements.</p>
                        
                        <p>Please read this policy carefully as it explains your rights in relation to your personal information and
                            how to contact us or supervisory authorities in the event you have a complaint.</p>
                        
                        <p>We may change this privacy from time to time, so you should check this policy occasionally to ensure you
                            are aware of the most recent version.</p>

                        <h3>What we collect</h3>

                        <p>The types of personal information we collect, and share depend on which of our websites or services you
                            use. In general, we may collect information that identifies you, information about how you use our websites
                            and services, and the information that you create while you interact with our websites and services.</p>

                        <p>We may collect and use the following personal information about you:</p>
                        
                        <ul>
                            <li>Your name and contact information, including email address and telephone number and company details</li>
                            <li>Your billing, delivery, transaction and payment card information</li>
                            <li>Your contact history, purchase history and saved items</li>
                            <li>Information to enable us to undertake credit or other financial checks on you</li>
                            <li>Information about how you use our website, IT, communication and other systems</li>
                            <li>Your responses to forms, surveys, competitions and promotions</li>
                            <li>Some pages use cookies, a data analysis technique</li>
                        </ul>

                        <p>This personal information is required to provide our products and services to you. If you do not provide
                        personal information we ask for, it may delay or prevent us from providing these to you.</p>

                        <h3>How we collect your personal information</h3>
                        <p>We collect most of this personal information directly from you - in person, by telephone, text or
                            email and/or via our website and services.</p>

                        <p>However, we may also collect information:</p>
                        <ul>
                            <li>From publicly accessible sources eg. Companies House</li>
                            <li>Directly from a third party eg. credit reference agencies</li>
                            <li>From a third party with your consent, eg your bank</li>
                            <li>From cookies on our website</li>
                            <li>Via our IT systems, eg. automated monitoring of our websites and other technical systems, such as our computer networks and connections, CCTV and access control systems, communications systems, email and instant messaging systems</li>
                        </ul>

                        <h3>How we use personal information</h3>
                        <p>We will only use your personal information if we have a proper reason for doing so.
                            The main reason we will use your information is to operate our business activities effectively,
                            understand your needs and provide you with a better service, and in particular for the following reasons:</p>
                        <ul>
                            <li>Internal record keeping</li>
                            <li>Improve our products and services</li>
                            <li>Customer care procedures</li>
                            <li>Provide information about new products, special offers or other relevant information you may find interesting</li>
                            <li>To offer you credit terms for the purchase of products or services from the site</li>
                            <li>Order fulfilment</li>
                            <li>Occasionally, we may contact you by e-mail, phone or mail for market research purposes</li>
                        </ul>

                        <h3>Who we share your personal information with</h3>
                        
                        <p>We may use your personal information for the purposes set out in this policy. We will not sell,
                            distribute or lease your personal information to third parties unless we have your permission or
                            are required by law.</p>
                        
                        <p>If our service providers do handle your personal information, we will only do so if we are satisfied
                            they take appropriate measures to protect your personal information. We also impose contractual obligations
                            on service providers relating to ensure they can only use your personal information to provide services
                            to us and to you.</p>

                        <h3>Promotional Communications</h3>
                        
                        <p>We may use your personal information to send you updates (by email, text message, telephone or mail) about
                            legal developments that might be of interest to you and/or information about our services, including
                            exclusive offers, promotions or new services or products.</p>
                        
                        <p>We have a legitimate interest in processing your personal information for promotional purposes to provide
                            you with the best service. This means we do not usually need your consent to send you promotional
                            communications. However, where consent is needed, we will ask for this consent separately and clearly.
                            We will always treat your personal information with the utmost respect.</p>
                        
                        <p>Always remember that at any time you have the right to opt out of receiving any promotional communications
        by e-mailing us at <a href="mailto:marketing@direktek.co.uk?Subject=Unsubscribe" target="_top">marketing@direktek.co.uk</a> (please put 'Unsubscribe' in the subject line).</p>

                        <h3>Requesting a copy of your personal information</h3>
                        
                        <p>You may request the personal information which we hold about you under previous and current data protection
                            legislation (and in particular the General Data Protection Regulations). Requested information will be
                            provided free of charge unless the volume of information justifies charging a reasonable fee
                            (based on the administrative cost of supplying the requested information), or the request is a
                            repeat request. Each of these types of cases will be considered individually.</p>
                        
                        <p>If you would like a copy of the information held on you, please email us at <a href="mailto:info-sec@direktek.co.uk?Subject=GDPR%20Subject%20Access%20Request" target="_top">info-sec@direktek.co.uk</a>
                            (please put 'GDPR Subject Access Request' in the subject line).</p>

                        <h3>Incorrect Information</h3>
                        
                        <p>If you believe that any information we are holding on you is incorrect or incomplete, we will correct
                            any information which was found to be incorrect promptly.</p>
                        
                        <p>Please let us know by emailing us at <a href="mailto:info-sec@direktek.co.uk?Subject=Incorrect%20Information" target="_top">info-sec@direktek.co.uk</a> (please put 'Incorrect Information' in the
                            subject line).</p>

                        <h3>Security</h3>
                        
                        <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or
                            disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and
                            secure the information we collect on line. We use encryption when collecting or transferring sensitive data.</p>

                        <h3>Cookies</h3>
                        
                        <p>A cookie is a piece of text that gets entered into the memory of your browser by a website,
                            allowing the website to store information on your machine and later retrieve it. We use cookies to
                            ensure that we give you the best experience on our website. The cookies used by our site are not used
                            for targeted marketing, only for website statistics and automation which will improve your browsing
                            experience. For example, when you visit our electronic store, a cookie makes it easier to shop, by
                            allowing you to place things into a shopping basket; the basket itself is not the cookie, the cookie
                            is placed on your hard drive and keeps track of your basket versus others in use at the same time.</p>
                        
                        <p>A cookie in no way gives us access to your computer or any information about you, other than the data you
                            choose to share with us. This practice is strictly in force. We know that people have concerns about
                            cookies, but we believe that the benefit we both gain from their proper use is worthwhile. You may set
                            your web browser to notify you of cookie placement requests or decline cookies completely. You can delete
                            the files that contain cookies - those files are stored as part of your internet browser.</p>
                        
                        <p>You can find more information about cookies at: <a href="http://www.allaboutcookies.org" target="_blank">www.allaboutcookies.org</a> and <a href="http://www.youronlinechoices.eu" target="_blank">www.youronlinechoices.eu</a>.</p>

                        <h3>Questions, comments or concerns</h3>
                        
                        <p>Please get in touch if you need to talk to us about anything by emailing <a href="mailto:info-sec@direktek.co.uk" target="_top">info-sec@direktek.co.uk</a>,
                            and we&#8217;ll do our best to help. If you&#8217;ve made a complaint and are still unhappy, you can speak with
                            the ICO: <a href="https://ico.org.uk/concerns" target="_blank">https://ico.org.uk/concerns</a>.</p>

                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
export default Privacy;